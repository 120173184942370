<template>
  <div class="cart-summary-actions">
    <div class="cart-summary-actions-inner">
      <!-- cart:actions:card&coupons START -->
      <div class="card-coupons">

        <!-- form:payback START -->
        <div class="card" v-if="box && box.hasCardCheckin === 1">
          <el-form
            status-icon
            :rules="rules"
            ref="paybackForm"
            :model="paybackForm"
            class="login-form"
            :statusIcon="false"
          >
            <!-- form:payback:title START -->
            <el-form-item prop="number">
              <span class="label" v-html="$t('terminal.cart.total.clientcard.headline')"></span>
              <el-input
                :placeholder="'XXXX-XXXX-XXXX-XXXX'"
                v-mask="'XXXX-XXXX-XXXX-XXXX'"
                v-model="paybackForm.number"
                disabled="disabled"
                :class="[
                  { 'correct' : cardNr === paybackForm.number.replace(/\-/g, '') }
                ]"
              ></el-input>
              <transition name="dialog-fade" mode="out-in">
                <span
                  class="correct"
                  v-if="cardNr === paybackForm.number.replace(/\-/g, '')"
                ></span>
              </transition>
            </el-form-item>
            <!-- form:payback:title END -->
          </el-form>
        </div>
        <!-- form:payback END -->

        <!-- form:coupons START -->
        <div class="coupons">

          <!-- form:coupons:form START -->
          <el-form
            status-icon
            :rules="voucherRules"
            ref="voucherForm"
            :model="voucherForm"
            class="login-form voucher-form"
            :statusIcon="false"
          >
            <!-- form:voucher:code START -->
            <el-form-item prop="number">
              <span class="label" v-html="$t('terminal.cart.total.voucher.headline')"></span>
              <el-input
                :placeholder="`${$t('terminal.cart.total.voucher.headline')}`"
                v-model="voucherForm.code"
                disabled="disabled"
              ></el-input>
            </el-form-item>
            <!-- form:voucher:code END -->

            <el-button
              :class="[
                'btn-default btn-inverted',
                { 'btn-disabled' : !voucherForm.code }
              ]"
              type="primary"
              v-on:click="addVoucher('voucherForm')"
              v-on:touchstart="touchStart($event)"
              v-on:touchend="touchEnd($event);"
              v-on:touchcancel="touchEnd($event)"
              v-html="$t('terminal.cart.total.voucher.redeem')"
            ></el-button>
          </el-form>
          <!-- form:coupons:form END -->

          <!-- form:coupons:all-coupons START -->
          <div class="vouchers">
            <div
              class="voucher"
              v-for="(voucher, voucherIndex) in vouchers"
              :key="`vouchers-voucher-${voucherIndex}`"
            >
              <span
                class="code"
                v-html="voucher.code"
              ></span>
              <span
                class="delete"
                v-on:click="deleteVoucher(voucher.id)"
                v-on:touchstart="touchStart($event)"
                v-on:touchend="touchEnd($event);"
                v-on:touchcancel="touchEnd($event)"
              ></span>
            </div>
          </div>
          <!-- form:coupons:all-coupons END -->

        </div>
        <!-- form:coupons END -->

      </div>
      <!-- cart:actions:card&coupons END -->

      <!-- cart:actions:pay START -->
      <div class="pay">
        <div class="pay-inner">

          <!-- cart:actions:checkout:info START -->
          <div class="info">
            <div class="value">
              <div class="headline" v-html="$t('terminal.cart.total.infovalue')"></div>
               <div class="price"
                v-html="`${products.currencySymbol} ${priceFormat(products.sum)}`"></div>
            </div>
            <div class="coupon">
              <div class="headline" v-html="$t('terminal.cart.total.coupon')"></div>
               <div
                class="price"
                v-html="`${products.currencySymbol} ${priceFormat(sumVouchers)}`"
              ></div>
            </div>
          </div>
          <!-- cart:actions:checkout:info END -->

          <!-- cart:actions:checkout:total START -->
          <div class="total">
            <div class="total-desc">
              <span
                class="total-headline"
                v-html="`
                  ${$t('terminal.cart.total.headline')}
                  <span class='vat'>${$t('terminal.cart.total.vat')}</span>
                `"
              ></span>
            </div>
            <transition name="scale" mode="out-in">
              <div :key="total" class="total-number scale-animation">
                <span
                  v-html="`${products.currencySymbol} ${priceFormat(products.sumTotal)}`"></span>
              </div>
            </transition>
          </div>
          <!-- cart:actions:checkout:total END -->

          <!-- cart:actions:checkout:done START -->
          <transition name="opacity" mode="out-in">
            <div class="done">
              <el-button
                class="btn-default btn-done"
                :disabled="products.items && products.items.length === 0"
                v-html="`${$t('terminal.cart.total.paylong')}`"
                v-on:click="submitForm('paybackForm')"
                v-on:touchstart="touchStart($event)"
                v-on:touchend="touchEnd($event);"
                v-on:touchcancel="touchEnd($event)"
              ></el-button>
            </div>
          </transition>
          <!-- cart:actions:checkout:done END -->

          <!-- cart:actions:checkout:abort START -->
          <div
            class="abort"
            v-on:click="resetStoreCart()"
          >
            <span v-html="$t('terminal.cart.total.abort')"></span>
          </div>
          <!-- cart:actions:checkout:abort END -->

        </div>
      </div>
      <!-- cart:actions:pay END -->
    </div>

    <!-- purchase:products:payback:loading START -->
    <loading-default
      :loadingVisible="loadingVisible"
      :headline="loadingHeadline"
      :excerpt="loadingExcerpt"
    />
    <!-- purchase:products:payback:loading END -->

    <!-- purchase:products:voucher:loading START -->
    <loading-default
      :loadingVisible="loadingVoucherVisible"
      :headline="loadingVoucherHeadline"
      :excerpt="loadingVoucherExcerpt"
    />
    <!-- purchase:products:voucher:loading END -->

    <!-- dialog:numpad START -->
    <dialog-numpad
      :dialogNumpadVisible="dialogNumpadVisible"
      :closeNumpad="closeNumpad"
    />
    <!-- dialog:numpad END -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'cart-actions-default',
  props: {
    products: {
      type: Object,
    },

    total: {
      type: Number,
    },

    sumVouchers: {
      type: Number,
    },

    categories: {
      type: Array,
    },

    priceFormat: {
      type: Function,
    },

    activeCategory: {
      type: Function,
    },

    dialogOpen: {
      type: Function,
    },

    addProduct: {
      type: Function,
    },

    isBarcodeActive: {
      type: Function,
    },

    done: {
      type: Function,
    },

    cardNr: {
      type: String,
    },

    box: {
      type: Object,
    },

    resetStoreCart: {
      type: Function,
    },
  },
  components: {
    DialogNumpad: () => import('../shared/dialog/Numpad.vue'),
    LoadingDefault: () => import('../shared/loading/Default.vue'),
  },
  data() {
    return {
      /**
       * Dialog
       */
      dialogNumpadVisible: false,

      /**
       * Loading
       */
      loadingVisible: false,
      loadingHeadline: this.$t('terminal.loading.summary.headline'),
      loadingExcerpt: this.$t('terminal.loading.summary.description'),

      /**
       * Loading (VOUCHER)
       */
      loadingVoucherVisible: false,
      loadingVoucherHeadline: this.$t('terminal.loading.voucher.headline'),
      loadingVoucherExcerpt: this.$t('terminal.loading.voucher.description'),

      /**
       * Form (Models)
       */
      paybackForm: {
        number: '',
      },

      /**
       * Form (Models)
       */
      voucherForm: {
        code: '',
      },

      /**
       * Form (Rules - Validation)
       */
      rules: {
        number: [
          {
            required: false,
            min: 19,
            max: 19,
            message: ' ',
          },
        ],
      },
      voucherRules: {
        code: [
          {
            required: true,
            message: this.$t('terminal.login.errorformfield'),
            trigger: [
              'blur',
              'change',
            ],
          },
        ],
      },

      /**
       * Scanner
       */
      scannedString: '',
    };
  },
  async created() {
    // fetch payment categories
    await this.setPaymentCategories();

    // if (Boolean(this.$route.query.pin) === true) {
    //   this.submitForm('paybackForm');
    // }

    // if (Boolean(this.$route.query.ecCard) === true) {
    //   this.submitForm('paybackForm');
    // }

    window.addEventListener('keydown', this.keyDownHandler);
  },
  computed: {
    ...mapGetters({
      paymentCategories: 'paymentCategories',
    }),

    vouchers() {
      if (this.$store.getters.storeCart) {
        return this.$store.getters.storeCart.vouchers;
      }
      return this.$store.state.storeCart;
    },
  },
  methods: {
    ...mapActions([
      'setPaymentCategories',
    ]),

    keyDownHandler(e) {
      // 10EUR
      // 15EUR

      const keyCode = e.keyCode || e.which;
      const code = e.code || '';
      let char = e.key;

      if (keyCode === 89 && code === 'KeyY' && char === 'Y') char = 'Z';
      else if (keyCode === 89 && code === 'KeyY' && char === 'y') char = 'z';
      else if (keyCode === 90 && code === 'KeyZ' && char === 'Z') char = 'Y';
      else if (keyCode === 90 && code === 'KeyZ' && char === 'z') char = 'y';

      if (char.length > 1) char = '';
      this.scannedString += char;

      if (e.keyCode === 13) {
        this.loadingVoucherVisible = true;
        if (this.scannedString === this.cardNr) {
          this.paybackForm.number = this.scannedString;
        } else {
          this.voucherForm.code = this.scannedString;
          this.addVoucher('voucherForm');
        }
        setTimeout(() => {
          this.loadingVoucherVisible = false;
          this.scannedString = '';
          this.voucherForm.code = '';
        }, 350);
      }
    },

    submitForm(formName) {
      this.loadingVisible = true;
      if (formName === 'paybackForm') {
        const formData = new FormData();
        formData.append('ordered_products', JSON.stringify(this.cart));
        formData.append('total', this.total);
        formData.append('payback_card_number', this.paybackForm.number);

        // Display the key/value pairs
        // formData.forEach((value, key) => {
        //   console.log(`${key}: ${value}`);
        // });

        // disable loading
        this.loadingVisible = false;

        if (Boolean(this.$route.query.directWithCard) === true) {
          let catID;
          let catName;
          this.paymentCategories.forEach((cat) => {
            if (cat.name === 'InShop') {
              catID = cat.id;
              catName = cat.name;
            }
          });
          this.$router.push({
            path: '/cart/checkout',
            query: {
              paymentCategoryID: catID,
              paymentCategoryName: catName,
            },
          });
        } else if (this.paymentCategories && this.paymentCategories.length === 1) {
          this.$router.push({
            path: '/cart/checkout',
            query: {
              paymentCategoryID: this.paymentCategories[0].id,
              paymentCategoryName: this.paymentCategories[0].name,
            },
          });
        } else {
          // redirect to payment methods screen
          this.$router.push('/cart/payment-methods');
        }
        return true;
      }
      return false;
    },

    addVoucher(formName) {
      const formData = new FormData();
      if (formName === 'voucherForm') {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.loadingVoucherVisible = true;
            formData.append('code', this.voucherForm.code);
            this.$http.post(`${process.env.VUE_APP_BASE_URL}/cart/voucher/add`, formData, {
              headers: {
                'X-Api-Key': `${process.env.VUE_APP_X_API_KEY}`,
                Authorization: `Bearer ${localStorage.getItem('auth')}`,
              },
            })
              .then((response) => {
                if (response.data) {
                  this.$store.commit('SET_CART', response.data);

                  this.success('Voucher wurde erfolgreich aktiviert!'); // translate

                  // close loading screen
                  setTimeout(() => {
                    this.loadingVoucherVisible = false;
                    this.voucherForm.code = '';
                  }, 350);
                } else {
                  this.globalErrorMessage = true;

                  // close loading screen
                  setTimeout(() => {
                    this.loadingVoucherVisible = false;
                    this.voucherForm.code = '';
                  }, 350);
                }
              })
              .catch((error) => {
                console.log(error);
                if (!error.response) {
                  // network error
                  this.errorStatus = 'Error: Network Error';
                } else {
                  this.errorStatus = error.response.data;
                  this.loadingVisible = false;
                  this.serverErrorMessages = this.errorStatus.errors;

                  this.serverErrorMessages.forEach((message) => {
                    this.error(`${this.$t(`error.${message.code}`)}`);
                  });
                }

                // close loading screen
                setTimeout(() => {
                  this.loadingVoucherVisible = false;
                  this.voucherForm.code = '';
                }, 350);
              });
          }
        });
      }
    },

    deleteVoucher(i) {
      this.loadingVoucherVisible = true;
      const formData = new FormData();
      formData.append('voucherID', i);
      // Update cart
      this.$http.post(`${process.env.VUE_APP_BASE_URL}/cart/voucher/delete`, formData, {
        headers: {
          'X-Api-Key': `${process.env.VUE_APP_X_API_KEY}`,
          Authorization: `Bearer ${localStorage.getItem('auth')}`,
        },
      })
        .then((response) => {
          if (response.data) {
            this.$store.commit('SET_CART', response.data);

            // close loading screen
            this.loadingVoucherVisible = false;
            this.voucherForm.code = '';

            this.success('Voucher wurde erfolgreich gelöscht!'); // translate
          } else {
            this.globalErrorMessage = true;

            // close loading screen
            this.loadingVoucherVisible = false;
            this.voucherForm.code = '';
          }
        })
        .catch((error) => {
          if (!error.response) {
            // network error
            this.errorStatus = 'Error: Network Error';
          } else {
            this.errorStatus = error.response.data;
            this.loadingVisible = false;
            this.serverErrorMessages = this.errorStatus.errors;

            this.serverErrorMessages.forEach((message) => {
              this.error(`${this.$t(`error.${message.code}`)}`);
            });
          }

          // close loading screen
          this.loadingVoucherVisible = false;
          this.voucherForm.code = '';
        });
    },

    resetVoucherForm(formName) {
      if (this.$refs[formName]) {
        this.globalErrorMessage = false;
        this.$refs[formName].resetFields();
      }
    },

    openNumpad() {
      this.dialogNumpadVisible = true;
      this.isBarcodeActive();
    },

    closeNumpad(ean) {
      this.dialogNumpadVisible = false;
      this.addProduct(Number(ean));
      this.isBarcodeActive();
    },

    touchStart(event) {
      event.target.classList.add('pressed');
    },

    touchEnd(event) {
      event.target.classList.remove('pressed');
    },

    success(message) {
      this.$notify({
        message,
        type: 'success',
        position: 'bottom-right',
        showClose: false,
        duration: 3000,
      });
    },

    error(message) {
      if (message) {
        this.$notify({
          message: this.$t(`${message}`),
          type: 'error',
          position: 'bottom-right',
          showClose: false,
          duration: 3000,
        });
      } else {
        this.$notify({
          message: this.$t('cart.product.failed'),
          type: 'error',
          position: 'bottom-right',
          showClose: false,
          duration: 3000,
        });
      }
    },
  },
  destroyed() {
    window.removeEventListener('keydown', this.keyDownHandler);
  },
};
</script>
